import { shuffleArray } from "../utils/shuffleArray";

const indexRequires = (requires: __WebpackModuleApi.RequireContext) => {
  return requires
    .keys()
    .map(requires)
};

// Note that due to webpack rules, we can't refactor these path strings to a variable :(
const fullImageRequires = require.context(
  "../images/galerie/fulls",
  false,
  /\.(jpg)$/
);

const bigImagesRequires = require.context(
  "../images",
  false,
  /\.(jpg)$/
);

export const fullImages = indexRequires(fullImageRequires);
export const thumbImages = fullImages;
export const randomThumbs = shuffleArray(fullImages);

export const bigImages = indexRequires(bigImagesRequires);
export const randomBigImages = shuffleArray(bigImages).map(x=> x.toString());

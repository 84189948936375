import { CSSProperties } from "react";
import { randomIntInRange } from "../utils/randomIntInRange";

export abstract class ColorHelper {
  // Returns black or white - whatever gives the better contrast
  private static getYiqContrastColor = (color: number[]): number[] => {
    // Get YIQ ratio, stolen from https://24ways.org/2010/calculating-color-contrast/
    var yiq = (color[0] * 299 + color[1] * 587 + color[2] * 114) / 1000;
    return yiq >= 128 ? [0, 0, 0] : [255, 255, 255];
  };

  private static getTweakedColorFromHue = (h: number) => {
    // tweak hue slighly; larger ranges for saturation and lightness
    const hueTweak = randomIntInRange(-40,40)
    const tweakedHue = h + hueTweak;
    if (tweakedHue > 360) {
        h = tweakedHue - h;
    }
    if (tweakedHue < 0) {
        h = tweakedHue - h;
    }
    const s = randomIntInRange(80, 100);
    const l = randomIntInRange(60, 90);

    return ColorHelper.HSLToRGB(h, s, l);
  };

  private static HSLToRGB = (h, s, l) => {
    // Must be fractions of 1
    s /= 100;
    l /= 100;

    let c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
      m = l - c / 2,
      r = 0,
      g = 0,
      b = 0;

    if (0 <= h && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (60 <= h && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (120 <= h && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (180 <= h && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (240 <= h && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else if (300 <= h && h < 360) {
      r = c;
      g = 0;
      b = x;
    }
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return [r, g, b];
  };

  // Returns a random color
  private static getRandomColor = (text?: string): number[] => {
    // 16777216 = 2^25 to generate a random color
    let hash = Math.floor(Math.random() * 16777216);

    // Function to calculate a hash on the text ... so the color matches the text description
    if (text) {
      hash = 0;
      for (let i = 0; i < text.length; i++) {
        hash = (hash << 5) - hash + text.charCodeAt(i);
        hash |= 0;
      }
    }

    // Generate the hex code from the newly created color, then add the alpha channel
    return [0, 1, 2].map((x) => (hash >> (x * 8)) & 255);
  };

  // Converts number array to rgba value
  private static toRgbaColor = (color: number[], alpha: number = 1): string => {
    const output = new Array<number>(...color);
    output.push(alpha);

    // Create the rgba string
    return `rgba(${output.join(",")})`;
  };

  /**
   * @description Gets a pair of contrast-safe colors in rgba(X,Y,Z, alpha) form as strings.
   * @param {number} alpha - The alpha, or opacity number the background color should contain.
   * @param {string} text - The text to be included - used to generate a random color in a deterministic fashion.
   * @param {Array<string>} colorAnchors - Starting point of colors to choose from when generating random colors
   * @return {{color: string; backgroundColor: string}}
   */
  public static getCSSColorPair(
    alpha: number,
    text?: string,
    colorHues?: Array<number>
  ): CSSProperties {
    let backgroundColorHex;
    if (colorHues) {
      const randomColorAnchor =
        colorHues[Math.floor(Math.random() * colorHues.length)];
      backgroundColorHex = ColorHelper.getTweakedColorFromHue(randomColorAnchor);
    } else {
      backgroundColorHex = ColorHelper.getRandomColor(text);
    }
    return {
      color: ColorHelper.toRgbaColor(
        ColorHelper.getYiqContrastColor(backgroundColorHex)
      ),
      backgroundColor: ColorHelper.toRgbaColor(backgroundColorHex, alpha),
    };
  }
}

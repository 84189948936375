import * as React from "react";
import { Tile } from "./Tile";
import { useStaticQuery, graphql } from "gatsby"
import { ITile } from "./ITile";

export const Tiles = () => {

  const data = useStaticQuery(graphql`
    query TileQuery {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `)

  const extendTiles = (links: any[]): ITile[] => {

    var input = new Array<ITile>();

    // We are popping the elements, so reverse them
    links.forEach(x => input.push({ isImage: false, text: x.name, link: x.link }));
    input.reverse();

    var result = new Array<ITile>();
    do {
      if (result.length % 2 == 0 && input.length > 0) {
        // Add text every second item
        let temp = input.pop();
        if(temp) {
          result.push(temp);
        }
      } else {
        // Ah, add a placeholder
        result.push({ isImage: true });
      }

    } while (result.length < 15)

    return result;
  }

  return (
    <div className="row mx-auto p-5 pb-0">
      {extendTiles(data.site.siteMetadata.menuLinks).map((x, i) => {
        return <Tile tile={x} tileIndex={i} key={i} />;
      })}
    </div>
  );
}

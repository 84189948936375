import * as React from "react";
import { Tiles } from "./Tiles";
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Copyright } from "../Copyright"

export function Sidebar() {
  return (
    <div className="sidebar">
      <div className="sidebar-wrapper">
        <div className="logo p-5">
          <Link to="/" >
            <StaticImage className="img-fluid" src="../../../images/sichtwechsel-logo.png" alt="sichtwechsel" />
          </Link>
        </div>
        <Tiles />
        <Copyright />
      </div>
    </div>
  );
}

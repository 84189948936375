import { Link } from "gatsby";
import * as React from "react";
import { useEffect, useState } from "react";
import { Constants } from "../../../constants/Constants";
import { ColorHelper } from "../../../helpers/ColorHelper";
import { thumbImages } from "../../../helpers/imageHelpers";
import { shuffleArray } from "../../../utils/shuffleArray";
import { ITile } from "./ITile";

export interface ITileProps {
  tile: ITile;
  tileIndex: number;
}

export function Tile(props: ITileProps) {
  const { tile, tileIndex } = props;
  const [randomThumbs, setRandomThumbs] = useState<Array<unknown>>(thumbImages);
  const { text, link, isImage } = tile;

  // on each render, shuffle the thumbs array
  useEffect(() => {
    setRandomThumbs(shuffleArray(thumbImages));
  }, [randomThumbs]);

  if (isImage) {
    const imageIndex = Math.ceil(tileIndex / 2) - 1;
    const image = randomThumbs[imageIndex];

    return (
      <div className="col-md-4 p-0 d-none d-md-block tile">
        <img className="h-100 w-100" src={image} alt="Bild aus der Galerie" />
      </div>
    );
  }

  const colorPair = ColorHelper.getCSSColorPair(1, text, [
    Constants.BLUE_COLOR_HUE,
    Constants.GREEN_COLOR_HUE,
  ]);

  return (
    <Link to={link} style={colorPair} className="col-md-4 p-0 tile">
      {text}
    </Link>
  );
}

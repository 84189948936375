import * as React from "react"
export class Copyright extends React.Component {

  private year: number = (new Date()).getUTCFullYear();

  render() {

    return (
      <p className="pl-5 pr-5 d-none d-sm-block copyright">
        © 2007 - {this.year} <a href="https://www.sichtwechsel.li">sichtwechsel</a>. 
        realisiert von&nbsp;<a href="https://www.miratools.com" target="_miratools" ref="external" title="Qualitätssoftware und Internetauftritte nach Mass (ALT + M)">miratools</a>.
      </p>
    )
  }
}
